.ellipsis {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
/*清除浮动*/
.clearfix {
  *zoom: 1;
}
.clearfix:after {
  display: table;
  line-height: 0;
  content: "";
  clear: both;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.title-model-wrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 6px);
  margin: 0 1px 10px;
}
.title-model-wrapper .title-p {
  line-height: 1.8;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: 14px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-model-wrapper.title1 {
  overflow: hidden;
  background: #00B64F;
  padding-right: 50px;
}
.title-model-wrapper.title1 .title-p {
  color: #fff;
}
.title-model-wrapper.title1:after {
  content: '';
  position: absolute;
  top: 0;
  right: 10px;
  width: 100px;
  height: 100%;
  background: url('../../assets/images/icon-title-right-arrow.png') no-repeat right center;
  background-size: auto 100%;
}
.title-model-wrapper.title2 {
  background: #DDFCFF;
  border-top: 2px solid #00B64F;
}
.title-model-wrapper.title2 .title-p {
  color: #00B64F;
}
.title-model-wrapper.title3 {
  border-bottom: 2px solid rgba(37, 182, 197, 0.5);
  padding-bottom: 6px;
}
.title-model-wrapper.title3 .title-p {
  line-height: 1.4;
  color: #00B64F;
}
.title-model-wrapper.title3:before {
  content: '';
  width: 100%;
  border-bottom: 4px solid #00B64F;
  position: absolute;
  bottom: 2px;
}
.title-model-wrapper.title4 {
  border-radius: 0 2em 2em 0;
  background: #00B64F;
}
.title-model-wrapper.title4 .title-p {
  color: #fff;
}
.title-model-wrapper.title5 .title-p {
  color: #4B525B;
}
.title-model-wrapper.title5:before {
  position: absolute;
  left: 0;
  top: 50%;
  content: '';
  width: 3px;
  height: 14px;
  background: #00B64F;
  border-radius: 3px;
  transform: translateY(-50%);
}
.divider-line {
  height: 20px;
  position: relative;
  margin: 5px 0;
  display: inline-block;
  width: 99%;
  cursor: pointer;
}
.divider-line.divider-line1:after {
  content: '';
  width: 100%;
  height: 1px;
  background: #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line2:after {
  content: '';
  width: 100%;
  height: 2px;
  background: #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line3:after {
  content: '';
  width: 100%;
  height: 1px;
  border-top: 1px dashed #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line4:after {
  content: '';
  width: 100%;
  height: 2px;
  border-top: 2px dashed #727981;
  position: absolute;
  top: 50%;
  left: 0;
}
.divider-line.divider-line5 {
  background: url("../images/divider-bg5.png") no-repeat center;
  background-size: auto 18px;
}
.divider-line.divider-line6 {
  background: url("../images/divider-bg6.png") no-repeat center;
  background-size: auto 11px;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  outline: none;
  font-family: -apple-system, system-ui, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", Arial;
  word-break: break-word;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 0, 0, 0);
}
html {
  min-height: 100vh;
  background: #f9fafe;
}
a {
  text-decoration: none;
}
body {
  font-size: 14px;
}
input,
textarea {
  border-radius: 0;
}
.mouse-copy {
  cursor: url("../images/mouse-copy.png"), default;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
  list-style: none;
}
input,
button,
textarea {
  -webkit-appearance: none;
  outline: none;
}
button {
  cursor: pointer;
}
.hidden {
  display: none !important;
}
.user-name-link {
  color: #00B64F;
  text-decoration: none;
  cursor: pointer;
  vertical-align: middle;
}
.user-name-link:hover {
  text-decoration: underline;
}
.W100 {
  width: 100%;
}
.W100px {
  width: 100px;
}
.W120px {
  width: 120px;
}
.W150px {
  width: 150px;
}
.W200px {
  width: 200px;
}
.W400px {
  width: 400px;
}
.H10px {
  height: 10px;
}
.H20px {
  height: 20px;
}
.tCenter {
  text-align: center;
}
.tRight {
  text-align: right;
}
.tLeft {
  text-align: left;
}
.m10 {
  margin: 10px;
}
.m20 {
  margin: 20px;
}
.mT10 {
  margin-top: 10px;
}
.mT12 {
  margin-top: 12px;
}
.mT20 {
  margin-top: 20px;
}
.mL5 {
  margin-left: 5px;
}
.mT5 {
  margin-top: 5px;
}
.mL10 {
  margin-left: 10px;
}
.mL12 {
  margin-left: 12px;
}
.mL20 {
  margin-left: 20px;
}
.mR20 {
  margin-right: 20px;
}
.mR3 {
  margin-right: 3px;
}
.mR10 {
  margin-right: 10px;
}
.mR12 {
  margin-right: 12px;
}
.mB10 {
  margin-bottom: 10px;
}
.mB12 {
  margin-bottom: 12px;
}
.mT16 {
  margin-top: 16px;
}
.mB16 {
  margin-bottom: 16px;
}
.mB20 {
  margin-bottom: 20px;
}
.mR8 {
  margin-right: 8px;
}
.mL8 {
  margin-left: 8px;
}
.iconfont {
  font-size: inherit;
}
@keyframes text-scroll {
  0% {
    left: 100%;
  }
  25% {
    left: 50%;
  }
  50% {
    left: 0%;
  }
  75% {
    left: -50%;
  }
  100% {
    left: -100%;
  }
}
.text-scroll {
  white-space: nowrap;
  position: relative;
  animation: text-scroll 3s linear infinite;
  animation-delay: 3s;
}
.text-scroll-5s {
  white-space: nowrap;
  position: relative;
  animation: text-scroll 5s linear infinite;
  animation-delay: 3s;
}
.text-scroll-8s {
  white-space: nowrap;
  position: relative;
  animation: text-scroll 8s linear infinite;
  animation-delay: 3s;
}
.text-scroll-10s {
  white-space: nowrap;
  position: relative;
  animation: text-scroll 10s linear infinite;
  animation-delay: 3s;
}
.text-scroll-15s {
  white-space: nowrap;
  position: relative;
  animation: text-scroll 15s linear infinite;
  animation-delay: 3s;
}
.secondary-content img[lazy="loading"],
.wiki-detail-content img[lazy="loading"] {
  width: 100% !important;
  height: auto !important;
}
.has-hidden {
  display: none !important;
}
.el-dialog {
  border-radius: 6px;
}
.el-dialog__headerbtn .el-dialog__close {
  font-size: 18px;
  color: #000;
}
.el-avatar {
  vertical-align: middle;
}
.el-dialog__body {
  padding: 10px 20px;
}
.el-dialog--center .el-dialog__body {
  padding: 10px 20px;
}
.el-button--primary {
  color: #fff;
  background-color: #00AFE8;
  border-color: #00AFE8;
}
.el-button--mini {
  padding: 5px 10px;
}
.el-button--primary:focus,
.el-button--primary:hover {
  color: #fff;
  background-color: #00AFE8;
  border-color: #00AFE8;
}
.el-message-box {
  max-width: 90%;
}
.el-select .el-tag {
  box-sizing: border-box;
  border-color: transparent;
  margin: 2px 0 2px 6px;
  background-color: #f0f2f5;
  display: flex;
  max-width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.el-select__tags-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.el-select .el-tag__close.el-icon-close {
  top: 1px;
  left: 6px;
  flex-shrink: 0;
}
.tag-color {
  border-radius: 4px;
  padding: 0 6px;
  height: 20px;
  font-size: 12px;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
  border: 1px solid;
}
.tag-color.tag-color-purple {
  border-color: #b936f0;
  color: #b936f0;
}
.tag-color.tag-color-gold {
  border-color: #d1ad25;
  color: #d1ad25;
}
.tag-color.tag-color-cyan {
  border-color: #1ebfa7;
  color: #1ebfa7;
}
.tag-color.tag-color-green {
  border-color: #00cb1e;
  color: #00cb1e;
}
.tag-color.tag-color-brown {
  border-color: #ba7a47;
  color: #ba7a47;
}
.tag-color.tag-color-blue {
  border-color: #3694ff;
  color: #3694ff;
}
.tag-color.tag-color-deep-red {
  border-color: #993a3a;
  color: #993a3a;
}
.tag-color.tag-color-orange {
  border-color: #ec9d00;
  color: #ec9d00;
}
.tag-color.tag-color-red {
  border-color: #ff6060;
  color: #ff6060;
}
.tag-color.tag-color-gray {
  border-color: #dbdbdb;
  color: #dbdbdb;
}
.span-point {
  border-radius: 10px;
  width: 8px;
  height: 8px;
  font-size: 0;
  color: #fff;
  display: inline-flex;
  align-content: center;
  justify-content: center;
  line-height: 20px;
}
.span-point.span-point-purple {
  background: #b936f0;
}
.span-point.span-point-gold {
  background: #d1ad25;
}
.span-point.span-point-cyan {
  background: #1ebfa7;
}
.span-point.span-point-green {
  background: #00cb1e;
}
.span-point.span-point-brown {
  background: #ba7a47;
}
.span-point.span-point-blue {
  background: #3c55d6;
}
.span-point.span-point-deep-red {
  background: #993a3a;
}
.span-point.span-point-orange {
  background: #f8a429;
}
.span-point.span-point-red {
  background: #e56352;
}
.span-point.span-point-gray {
  background: #dbdbdb;
  color: #868686;
}
.article-row-tag {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
  gap: 10px;
}
.article-row-tag .title {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  flex: 1;
  color: #424242;
}
.article-item-btn {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  color: #424242;
  width: 100%;
  height: 30px;
  background: #f2f2f2;
  border: 1px solid #dfdfdf;
  padding: 0 4px;
  border-radius: 2px;
  line-height: 28px;
}
.article-item-btn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
  margin-top: 2px;
}
.article-item-btn span {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 32px);
  display: block;
}
.article-item-btn + .article-item-btn {
  margin-top: 8px;
}
.app-bar {
  width: 100%;
  height: 44px;
  text-align: center;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1000;
}
.app-bar .app-bar-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 44px;
  background: #fff;
}
.app-bar .back-btn {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 20px;
  color: #666;
}
.app-bar .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #404040;
}
.app-bar .logo {
  height: 16px;
  width: 150px;
  background: url("../../assets/images/logo2.png") no-repeat left center;
  background-size: auto 100%;
}
.app-bar .bar-r-wrapper {
  display: flex;
  align-items: center;
}
.app-bar .bar-r-wrapper .user {
  color: #fff;
}
.app-bar .bar-r-wrapper .user img {
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  object-fit: cover;
}
.app-bar .bar-r-wrapper .search-btn,
.app-bar .bar-r-wrapper .red-point,
.app-bar .bar-r-wrapper .user {
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: #fff;
  font-size: 20px;
}
.app-bar .bar-r-wrapper .red-point {
  position: relative;
}
.app-bar .bar-r-wrapper .red-point span {
  position: absolute;
  top: 0;
  left: 20px;
  display: flex;
  align-items: center;
  padding: 0 4px;
  word-break: keep-all;
  background: red;
  border-radius: 10px;
  height: 16px;
  font-size: 12px;
  color: #fff;
}
.publish-miniapp-tips {
  width: 355px;
  min-width: 100px;
  height: 216px;
  background: url("../../assets/images/miniapp-publish-tips-bg.png") no-repeat;
  background-size: 100% 100%;
  border-radius: 8px;
  padding: 65px 12px 11px 14px;
  overflow: visible;
  border: none;
  box-shadow: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}
.publish-miniapp-tips .el-message__icon {
  display: none;
}
.publish-miniapp-tips .el-message__closeBtn {
  top: 62px;
  right: -36px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.6);
  text-align: center;
  line-height: 24px;
}
.publish-miniapp-tips .el-message__closeBtn::before {
  font-size: 16px;
  color: #fff;
}
.publish-miniapp-tips .miniapp-tips-content {
  width: 100%;
}
.publish-miniapp-tips .miniapp-tips-content .title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #216b7e;
  line-height: 22px;
  font-weight: 900;
  margin-bottom: 18px;
}
.publish-miniapp-tips .miniapp-tips-content .title img {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}
.publish-miniapp-tips .miniapp-tips-content .content {
  width: 100%;
  display: flex;
  gap: 19px;
}
.publish-miniapp-tips .miniapp-tips-content .content .qrcode {
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  background: #ffffff;
}
.publish-miniapp-tips .miniapp-tips-content .content .qrcode img {
  width: 100%;
}
.publish-miniapp-tips .miniapp-tips-content .content .tips {
  flex: 1;
  width: 100%;
}
.publish-miniapp-tips .miniapp-tips-content .content .tips .title {
  font-weight: 600;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  margin-bottom: 6px;
}
.publish-miniapp-tips .miniapp-tips-content .content .tips .desc {
  font-weight: 400;
  font-size: 11px;
  color: #666666;
  line-height: 16px;
  margin-bottom: 23px;
}
.publish-miniapp-tips .miniapp-tips-content .content .tips .tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.publish-miniapp-tips .miniapp-tips-content .content .tips .tags span {
  display: inline-block;
  width: 68px;
  height: 24px;
  background: #e8f7ff;
  border-radius: 14px;
  font-weight: 400;
  font-size: 11px;
  color: #4e7d95;
  line-height: 22px;
  text-align: center;
}
